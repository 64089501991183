import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearSucceededResponse } from '../../../../reducers/reportSlice';
import { setCredentials } from '../../../../reducers/messageSlice';
import { routes } from '../../../../routing/routeConsts';
import { languages } from '../../../../consts';
import { automaticRedirectionTime } from '../../../../consts'
import { changeLanguage, getLanguage } from '../../../../helpers/languageHelper';
import { storageHelper } from '../../../../helpers/storageHelper';
import AndroidAppLink from '../../../common/AndroidAppLink';
import IosAppLink from '../../../common/IosAppLink';
import { authHelper } from '../../../../api/authHelper';
import Modal from '../../../common/modals/Modal';
import ScreenshotWarningModal from '../../../common/modals/ScreenshotWarningModal';
import Header from '../../../common/Header';
import InteractiveInput from '../../../common/InteractiveInput';
import { credentialsDefault } from '../../../../consts';

export default function SuccessSubmissionPage() {
    const [showWarning, setShowWarning] = useState(true);
    const succeededResponse = useSelector(state => state.report.succeededResponse);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shouldBeTranslated = languages[0].code !== getLanguage().code;
    const userHumanId = authHelper.getUserHumanId();
    const [number, setNumber] = useState();
    const [pin, setPin] = useState();
    const canAccessChat = number?.filter(x => x || x === 0) && pin?.filter(x => x || x === 0).length === 5;
    const credentials = useSelector(state => state.message.credentials);

    const enterChat = () => {
      dispatch(setCredentials({ pin: pin.join(""), number: number.join("") }));
      navigate(routes.chat);
    }

    useEffect(() => {
        if (!succeededResponse) {
            navigate(routes.home);
        }

        let userInactivityTimer = setTimeout(function () {
            navigate(routes.home);
        }, automaticRedirectionTime);

        let navigationButtons = document.querySelectorAll('.button_bordered, .human-id__button');
        for (let i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].onclick = function() {
                clearTimeout(userInactivityTimer);
            };
        }

        const credentials = { number: succeededResponse?.reportId?.toString(), pin: succeededResponse?.pin };

        if (shouldBeTranslated) {
            storageHelper?.setMessageCredentials(credentials);
        }

        dispatch(setCredentials(credentials));

        return () => {
            dispatch(clearSucceededResponse());

            if (shouldBeTranslated) {
                changeLanguage(getLanguage().code)
            }
        }
    }, [])

    useEffect(() => {
        const storedCredentials = storageHelper.getMessageCredentials();

        if (!credentials && storedCredentials) {
            dispatch(setCredentials(storedCredentials));
            storageHelper.removeMessageCredentials();
            return;
        }
        setNumber(credentials?.number?.split("") || credentialsDefault);
        setPin(credentials?.pin?.split("") || credentialsDefault);
    }, [credentials])

    return succeededResponse && (
        <>
            <Header useLogo={true} showLanguage={true} />
            {showWarning &&
                <Modal
                    onNextClick={() => {
                        setShowWarning(false);
                    }}
                    onCancelClick={() => {
                        setShowWarning(false);
                    }}
                    component={ScreenshotWarningModal}
                    nextText='Okay'
                    cancelText='Cancel'
            />}
            <div className='report-succeeded__container tab-bar-predecessor container'>
                <div className='main'>
                    <div className='html-markup__container'>
                        <div className='report-succeeded__thank-you-text text_default text_bold text_enlarged' 
                            dangerouslySetInnerHTML={{ __html: succeededResponse.ThankYouText } || 'Thank You'} />
                    </div>
                    <div className="text_default text_bold text_block">
                        The Report Number and Pin are required to view messages related to your report. <br/> 
                        You can check for new messages at <a href="https://www.reportit.com/messages" target='_blank' className="link-inline">www.reportit.com/messages</a> on your mobile device
                    </div>
                    <div className="text_default_gray input_label">Report Number</div>
                    <InteractiveInput
                        inputValue={number}
                        onInputValueChange={value => setNumber(value)}
                        isExtensible={false}
                        isDisabled={true}
                        uniquePrefix="number"
                    />
                    <div className="text_default_gray input_label">Pin</div>
                    <InteractiveInput
                        inputValue={pin}
                        onInputValueChange={value => setPin(value)}
                        isExtensible={false}
                        isDisabled={true}
                        uniquePrefix="pin"
                        onSubmit={canAccessChat ? () => enterChat() : null}
                    />
                </div>
                <div className='report-succeeded__navigation-container'>
                    {userHumanId 
                        ? <Link to={routes.messages} className='button_bordered report-succeeded__messages-button'>
                            View Messages
                        </Link>
                        : <div
                              className="button text_default"
                              onClick={() => enterChat()}>Continue
                        </div>
                    }
                    {userHumanId && 
                        (<div className='report-succeeded__download-apps-icons__container'>
                            <AndroidAppLink />
                            <IosAppLink />
                        </div>)
                    }
                </div>
            </div>
        </>
    )
}