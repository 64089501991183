import React, { useEffect, useState } from 'react'

export default function InteractiveInput({ inputValue, onInputValueChange, isExtensible = true, isDisabled = false, uniquePrefix, onSubmit }) {
    const maxInputCount = 20;
    const maxWidth = document.documentElement.clientWidth - 20;
    const maxFullSizedNumber = ~~(maxWidth / 35);

    const getInputWidth = number => {
        return number > maxFullSizedNumber - 1
            ? maxWidth / (isExtensible ? number : 5) : 8;
    };
    const getFontSize = number => {
        return !number || number < 13 ? 24 : (number < 15 ? 16 : 12);
    }
    const initialInputsCount = 5;
    const initialFontSize = 24;
    const [inputWidth, setInputWidth] = useState(getInputWidth(inputValue ? inputValue.length : initialInputsCount));
    const [fontSize, setFontSize] = useState(inputValue ? getFontSize(inputValue.length) : initialFontSize);
    const [isRemoveClicked, setIsRemoveClicked] = useState(false);
    const [isFocusNeeded, setIsFocusNeeded] = useState(false);
    const firstEmptyIndex = inputValue?.findIndex(x => x === "");

    const focusElement = index => {
        const element = document.getElementById(`interactive-input${uniquePrefix}__item_${index}`);
        if (element && index >= 0) {
            element.focus();
            return true;
        }

        return false;
    }

    useEffect(() => {
        if (!isFocusNeeded) {
            return;
        }

        if (!inputValue) {
            onInputValueChange(Array.from({ length: initialInputsCount }, x => ""));
        }
        if (inputValue && inputValue.filter(x => x !== "").length > 0) {
            const index = isRemoveClicked ? inputValue.findIndex(x => x === "") - 1 : inputValue.findIndex(x => x === "");
            const isSuccesful = focusElement(index);

            if (isSuccesful && isRemoveClicked) {
                setIsRemoveClicked(false);
            }
            setIsFocusNeeded(false);
        }
    }, [isFocusNeeded, inputValue]);

    return (
        <div className="interactive-input__container" style={{ maxWidth: maxWidth }}>
            {inputValue && inputValue.length > 0 &&
                inputValue.map((x, i) => (
                    <input
                        className="interactive-input"
                        id={`interactive-input${uniquePrefix}__item_${i}`}
                        key={`${uniquePrefix}_${i}`}
                        type="search"
                        pattern="[a-zA-Z0-9]"
                        inputMode="text"
                        value={x}
                        maxLength={1}
                        style={{ width: `${inputWidth}%`, fontSize: fontSize }}
                        disabled={isDisabled || (i > firstEmptyIndex && !x)}
                        onKeyDown={e => {
                            const key = e.key;
                            if (key === "Enter" && onSubmit) {
                                onSubmit();
                                return;
                            }
                            if (!((key === "Backspace" || key === "Delete") && e.target.value === "")) {
                                return false;
                            }
                            setIsRemoveClicked(true);
                            let newArray = [...inputValue];
                            if (isExtensible) {
                                newArray.splice(i, 1)

                                if (newArray.length <= initialInputsCount || newArray[newArray.length - 1] !== "") {
                                    newArray.push("");
                                    setInputWidth(getInputWidth(newArray.length));
                                    setFontSize(getFontSize(newArray.length));
                                }
                            } else {
                                newArray[i] = "";
                            }

                            onInputValueChange(newArray);
                            setIsFocusNeeded(true)
                        }}
                        onInput={e => {
                            let newArray = [...inputValue];
                            if (isExtensible) {
                                if (i === newArray.length - 1) {
                                    newArray.push("");
                                    setInputWidth(getInputWidth(newArray.length));
                                    setFontSize(getFontSize(newArray.length));
                                }

                                if (newArray.length >= maxInputCount) {
                                    return;
                                }

                                newArray.splice(i, 1, e.target.value);

                                if (newArray.length > initialInputsCount
                                    && newArray[newArray.length - 2] === ""
                                    && newArray[newArray.length - 1] === "") {
                                    newArray.splice(newArray.length - 1, 1)
                                }
                            }
                            else {
                                newArray[i] = e.target.value;
                            }

                            onInputValueChange(newArray);
                            setIsFocusNeeded(true)
                        }}
                    />
                ))}
        </div>
    )
}